import React, { useState, useEffect, useRef, useContext } from "react";
import "./App.css";
import { useReactMediaRecorder } from "react-media-recorder";
import { SocketContext } from "./socket";
import TextareaAutosize from "react-textarea-autosize";
import AttachmentIcon from "@mui/icons-material/Attachment";
import MicNoneIcon from "@mui/icons-material/MicNone";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import SettingsIcon from "@mui/icons-material/Settings";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CloseIcon from "@mui/icons-material/Close";
import ForumIcon from "@mui/icons-material/Forum";
import SendIcon from "@mui/icons-material/Send";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import DescriptionIcon from "@mui/icons-material/Description";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import debounce from "lodash/debounce";
import ContentDisplay from "./ContentDisplay";
import { styled } from "@mui/material/styles";

// let baseServerUrl = "http://localhost:5000";
let baseServerUrl = "https://gpt4bot-test.ds.ra-micro.de";

let playList = [];
let isFirstChunck = true;
let oldBolbVideo = null;
let tempAnswer = { content: "", role: "" };
let avatarList = [
  {
    video: "peter.mp4",
    icon: "peter.jpg",
    name: "Peter",
    role: "Generic",
    gender: "male",
  },
  {
    video: "mary.mp4",
    icon: "mary.jpg",
    name: "Mary",
    role: "Marketing",
    gender: "female",
  },
  {
    video: "melisa.mp4",
    icon: "melisa.jpg",
    name: "Melisa",
    role: "Public Relation",
    gender: "female",
  },
  {
    video: "daniel.mp4",
    icon: "daniel.jpg",
    name: "Daniel",
    role: "IT Specialist",
    gender: "male",
  },
  {
    video: "saber.mp4",
    icon: "saber.jpg",
    name: "Saber",
    role: "Developer",
    gender: "male",
  },
  {
    video: "elon.mp4",
    icon: "elon.jpg",
    name: "Elon",
    role: "Data Analyst",
    gender: "female",
  },
];

let latestMuteState = JSON.parse(localStorage.getItem("isMute"));

function ChildComponent() {
  const socket = useContext(SocketContext);
  const [isShowChatWidget, setIsShowChatWidget] = useState(false);
  const [textToSpeak, setTextToSpeak] = useState("");
  const [uploadedFileList, setUploadedFileList] = useState([]);
  const [textQuestion, setTextQuestion] = useState("");
  const [previousChats, setPreviousChats] = useState([]);
  const [chatAvatar, setchatAvatar] = useState(avatarList[0]);

  const videoRef = useRef();
  const inputRef = useRef(null);
  const fileRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false); // New state to track cancellation
  const { status, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({ audio: true });
  const [isMute, setIsMute] = useState(latestMuteState || false);
  const [isCopied, setisCopied] = useState({ state: false, id: 0 });
  const [openSetting, setOpenSetting] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  useEffect(() => {
    setTextToSpeak("");
  }, []);
  useEffect(() => {
    if (status === "stopped" && mediaBlobUrl) {
      handleUploadAudio();
    }
  }, [status, mediaBlobUrl]);

  useEffect(() => {
    if (textToSpeak.trim() !== "" && !isCanceled) {
      handleSpeak(textToSpeak);
    }
  }, [textToSpeak]);

  useEffect(() => {
    if (textQuestion.trim().length > 0) {
      setTextToSpeak(textQuestion);
      setPreviousChats((prevChats) => [
        ...prevChats,
        { role: "user", content: textQuestion, files:[]},
      ]);
     
    }
  }, [textQuestion]);

  useEffect(() => {
    let content = "";
    if (uploadedFileList.length > 0) {
      setPreviousChats((prevChats) => [
        ...prevChats,
        { role: "user", content: inputRef.current.value.trim(), files:uploadedFileList },
      ]);
      // handleSpeak(inputRef.current.value);
    }
  }, [uploadedFileList]);

  useEffect(() => {
    if(previousChats.length > 0){
      if(uploadedFileList.length > 0){
        handleSpeak(inputRef.current.value);
      
      }
      inputRef.current.value = "";
    }
  
  }, [previousChats])
  

  const handleSpeak = async (text) => {
    // Check if operation was canceled before proceeding
    if (isCanceled) return;
    setIsLoading(true);
    try {
      socket.emit("speak", {
        message: text,
        avatar: chatAvatar,
        isMute: isMute,
        files:uploadedFileList
      });
      socket.on("send_video", (data) => {
        console.log(data.video);
        let videoBlob = new Blob([data.video]);
        // if (videoBlob.type !== "video/mp4") throw new Error("Received blob is not a valid MP4 video.");
        if (oldBolbVideo != null && oldBolbVideo.size === videoBlob.size) {
          return;
        }
        oldBolbVideo = videoBlob;
        const videoUrl = URL.createObjectURL(videoBlob);
        if (isFirstChunck) {
          if (videoRef.current) {
            videoRef.current.src = videoUrl;
            videoRef.current.load();
            videoRef.current.oncanplaythrough = (event) => {
              videoRef.current.play();
              setIsLoading(false);
              createChatList();
            };
          } else {
            playList.push(videoUrl);
          }

          isFirstChunck = false;
        } else {
          playList.push(videoUrl);
        }
      });

      socket.on("send_text", (message) => {
        console.log(message.gptData);
        const data = message.gptData;

        if (
          data.choices &&
          Array.isArray(data.choices) &&
          data.choices.length > 0
        ) {
          if (
            tempAnswer.content === data.choices[0].message.content &&
            tempAnswer.role === data.choices[0].message.role
          ) {
            return;
          }

          tempAnswer = data.choices[0].message;
        } else {
          console.error(data);
          tempAnswer = null;
        }
        console.log(JSON.parse(localStorage.getItem("isMute")));

        if (JSON.parse(localStorage.getItem("isMute"))) {
          setIsLoading(false);
          createChatList();
        }
      });
    } catch (error) {
      console.error("Error fetching the video", error);
      revertToIdleVideo();
    }
  };

  const createChatList = () => {
    if (tempAnswer) {
      setUploadedFileList([]);
      setPreviousChats((prevChats) => [
        ...prevChats,
        { role: tempAnswer.role, content: tempAnswer.content, files:[] },
      ]);

      setTextQuestion("");
      setIsTyping(false);
    }
  };

  const handleUploadAudio = async () => {
    setIsLoading(true);
    if (!mediaBlobUrl) return;

    const audioBlob = await fetch(mediaBlobUrl).then((r) => r.blob());
    const formData = new FormData();
    formData.append("audio", audioBlob, "audio-file.mp3");

    try {
      const response = await fetch(baseServerUrl + "/backend/audio-to-text", {
        method: "POST",
        body: formData,
      });

      if (!response.ok)
        throw new Error("Failed to upload and transcribe audio");

      // Check if operation was canceled before proceeding
      if (isCanceled) return;

      const result = await response.json();
      setTextQuestion(result.text);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const revertToIdleVideo = () => {
    if (playList.length === 0) {
      videoRef.current.src = `./avatars/${chatAvatar.video}`;
      isFirstChunck = true;
    } else {
      setIsCanceled(false); // Reset the cancellation state
      videoRef.current.src = playList.shift();
    }
    videoRef.current.load();
    videoRef.current.oncanplaythrough = (event) => {
      videoRef.current.play();
    };
  };

  const handleAskAI = () => {
    setIsCanceled(false);
    if (selectedFiles.length > 0) {
      handleUploadFile();
      return;
    }
    if (inputRef.current.value.trim() !== "") {
      setTextQuestion(inputRef.current.value);
    }
  };

  const handleRecordAndSend = () => {
    setIsCanceled(false); // Reset the cancellation state
    // handleUploadAudio();
    if (status === "recording") {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const handleCancel = () => {
    if (status === "recording") {
      stopRecording();
    }
    setTextQuestion("");
    playList = [];
    revertToIdleVideo();
    socket.io.engine.close();
    socket.emit("cancel", true);
    setIsCanceled(true); // Set the cancellation state
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleAskAI();
    }
  };

  function useChatScroll(dep) {
    const ref = React.useRef();
    React.useEffect(() => {
      if (ref.current) {
        ref.current.scrollTop = ref.current.scrollHeight;
      }
    }, [dep]);
    return ref;
  }

  const ref = useChatScroll(previousChats);

  const RenderAvatarListItems = () => {
    return (
      <div className="avatar-list">
        {avatarList?.map((item, index) => (
          <div
            className={item.name===chatAvatar.name ? "avatar-list-items selected" : "avatar-list-items"}
            key={index}
            onClick={() => {
              onChangeAvatar(index);
            }}
          >
            <img width="30" height="30" src={item.icon} alt="5 Terre" />
            <span>
              {item.name} | {item.role}
            </span>
          </div>
        ))}
      </div>
    );
  };

  const RenderSelectedFileListItems = () => {
    return (
      <div
        className={
          selectedFiles.length > 0
            ? "file-list-area attached"
            : "file-list-area"
        }
      >
        {selectedFiles?.map((item, index) => (
          <div className="file-list-items" key={index}>
            <DescriptionIcon fontSize="large" />
            <span>{item.name}</span>
            <IconButton
              aria-label="settingsIcon"
              size="small"
              onClick={() => onRemoveAttachedFile(index)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
        ))}
      </div>
    );
  };

  const RenderUploadedFileListItems = (props) => {
    return (
      <div className="uploaded-file-list-area">
        {props.list?.map((item, index) => (
          <div className="uploaded-file-list-items" key={index}>
            <DescriptionIcon fontSize="large" />
            <span>{item.originalname}</span>
          </div>
        ))}
      </div>
    );
  };

  const onRemoveAttachedFile = (index) => {
    setSelectedFiles(
      selectedFiles.filter((item, i)=> i!==index)
    );
  };

  const onChangeAvatar = (index) => {
    setchatAvatar(avatarList[index]);
    setOpenSetting(false);
  };

  const resetChat = () => {
    handleCancel();
    setPreviousChats([]);
  };

  const onChangeVoulmnState = () => {
    localStorage.setItem("isMute", !isMute);
    if (!isMute) {
      playList = [];
      revertToIdleVideo();
      socket.io.engine.close();
      socket.emit("cancel", true);
    }
    setIsMute(!isMute);
  };

  // const delayedStateUpdate = debounce((value) => {
  //   setTextQuestion(value);
  // },200);

  const handleInputChange = (e) => {
    if(e.target.value.trim().length > 0) setIsTyping(true);
    else setIsTyping(false);
  };

  const copyContent = async (text, id) => {
    try {
      await navigator.clipboard.writeText(text);
      setisCopied({ state: true, id: id });
      setTimeout(() => {
        setisCopied({ state: false, id: id });
      }, 2000);
      console.log("Text copied to clipboard");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const handleClickSetting = (event) => {
    setOpenSetting((prev) => !prev);
  };

  const Input = styled("input")({
    display: "none",
  });

  const handleFileChange = (event) => {
    console.log(event.target.files);
    console.log(Object.values(event.target.files));
    setSelectedFiles(Object.values(event.target.files));
  };

  const handleUploadFile = async () => {
    setIsLoading(true);
    if (!selectedFiles.length === 0) return;

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("files", selectedFiles[i]);
    }

    try {
      const response = await fetch(baseServerUrl + "/backend/file-upload", {
        method: "POST",
        body: formData,
      });

      if (!response.ok)
        throw new Error("Failed to upload and transcribe audio");
      let res = await response.json();
      console.log(res);
      setUploadedFileList(res);
      setSelectedFiles([]);
      // Check if operation was canceled before proceeding
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const isIframe = () => {
    // Assume we have a URL like: http://example.com/?isIframe=true
    // This will give us the current URL.
    const queryString = window.location.search;
    // This creates a URLSearchParams object which can be used to access the parameters
    const urlParams = new URLSearchParams(queryString);
    // Get the value of 'isIframe'
    const isIframe = urlParams.get('isIframe');
    return isIframe;
  }
  const hiddenCloseBtn = { visibility: isIframe() ? 'hidden' : '' };

  return (
    <div className={isIframe()? 'AppIframe' : 'App'}>
      <div className="main-container">
        {isShowChatWidget || isIframe() ? (
          <div className="wrapper">
            <div className="avatar">
              <video
                ref={videoRef}
                autoPlay
                width={150}
                height={150}
                src={`./avatars/${chatAvatar.video}`}
                onEnded={revertToIdleVideo}
              >
                Your browser does not support the video tag.
              </video>
              {isLoading && (
                <div className="spinner-container">
                  <div className="spinner"></div>
                </div>
              )}
            </div>
            <div className="header">
              <div></div>
              <div className="title">
                {chatAvatar.name} - {chatAvatar.role}
              </div>
              <div className="header-icon-container">
                <IconButton
                  aria-label="settingsIcon"
                  size="small"
                  onClick={(e) => handleClickSetting(e)}
                >
                  <SettingsIcon size="small" />
                </IconButton>
                <IconButton
                  aria-label="settingsIcon"
                  size="small"
                  onClick={() => resetChat()}
                >
                  <AutorenewIcon size="small" />
                </IconButton>
                  <IconButton
                    style={hiddenCloseBtn}
                    aria-label="settingsIcon"
                    size="small"
                    onClick={() => setIsShowChatWidget(false)}
                  >
                    <CloseIcon size="small" />
                  </IconButton>
              </div>
            </div>
            {openSetting && <div className="setting-content">
                <RenderAvatarListItems />
            </div>}
            <div className="box" ref={ref}>
              <div className="message-item start">
                <div className="msg">
                  <div>
                    Hello! I'm {chatAvatar.name}, your assistant ready to assist you. Do you
                    have any questions in mind? I can offer assistance in a
                    variety of areas. If you need specific help, select one of
                    my colleagues:
                  </div>
                  <div className="avatar-list-container">
                    <RenderAvatarListItems />
                  </div>                  
                  <div>
                    Alternatively, if you prefer, I can answer your general
                    questions myself. How can I assist you today?
                  </div>
                </div>
              </div>

              {previousChats?.map((chatMessage, index) => (
                <div key={index}>
                  {chatMessage.role === "user" ? (
                    <div className="message-item right">
                      <div className="msg">
                        {chatMessage.files.length > 0 && (
                          <div>
                            <RenderUploadedFileListItems list={chatMessage.files} />
                          </div>
                        )}
                        <div>{chatMessage.content}</div>
                      </div>
                    </div>
                  ) : (
                    <div className="message-item">
                      <div className="msg">
                        {chatMessage.content.split("```").map((part, index) => (
                          // Every second element in contentParts is considered code based on our split logic
                          <ContentDisplay
                            key={index}
                            content={part}
                            isCode={index % 2 !== 0}
                            language={part.split("\n")[0]}
                          />
                        ))}
                        <div
                          className="content-copy-btn"
                          onClick={() =>
                            copyContent(chatMessage.content, index)
                          }
                        >
                          {isCopied.state && isCopied.id === index ? (
                            <IconButton
                              aria-label="assignmentTurnedInIcon"
                              size="small"
                            >
                              <AssignmentTurnedInIcon fontSize="small" />
                            </IconButton>
                          ) : (
                            <Tooltip title="copy content">
                              <IconButton
                                aria-label="contentCopyIcon"
                                size="small"
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div>
              <RenderSelectedFileListItems />
              <div
                className={
                  isTyping
                    ? "typing-area typing"
                    : "typing-area"
                }
              >
                <Input
                  ref={fileRef}
                  accept=".DOC,.DOCX,.DOT,.PDF,.CSV,.TXT,.XLS,.XLSX" // Adjust this according to the file types you want to accept
                  id="icon-button-file"
                  type="file"
                  multiple
                  onChange={handleFileChange}
                />
                <label htmlFor="icon-button-file">
                  <IconButton
                    // color="primary"
                    aria-label="upload file"
                    component="span"
                  >
                    <AttachmentIcon size="small" />
                  </IconButton>
                </label>

                <div className="input-field">
                  <TextareaAutosize
                    ref={inputRef}
                    className="prompt-textarea"
                    placeholder={`Ask ${chatAvatar.name}..`}
                    minRows={1}
                    maxRows={3}
                    onChange={handleInputChange}
                    onKeyDown={(ev) => handleKeyPress(ev)}
                  />
                </div>
                {isTyping || selectedFiles.length > 0 ? (
                  <div className="recording-btn" onClick={handleAskAI}>
                    <SendIcon />
                  </div>
                ) : (
                  <>
                    <div
                      className={
                        status === "recording"
                          ? "recording-btn recording"
                          : "recording-btn"
                      }
                      onClick={handleRecordAndSend}
                    >
                      <MicNoneIcon />
                    </div>
                    <div className="sound-btn" onClick={onChangeVoulmnState}>
                      {isMute ? <VolumeOffIcon /> : <VolumeUpIcon />}
                    </div>
                  </>
                )}
             
              </div>
            </div>
          </div>
        ) : (
          <div className="QA-btn" onClick={() => setIsShowChatWidget(true)}>
            <ForumIcon fontSize="large" />
          </div>
        )}
      </div>
    </div>
  );
}

export default ChildComponent;
