import React, { useState, useEffect, useRef } from "react";
import Prism from "prismjs";
import "prismjs/themes/prism-okaidia.css"; // Theme
import "prismjs/plugins/toolbar/prism-toolbar.css";
import "prismjs/plugins/toolbar/prism-toolbar";
import "prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard";
import "prismjs/plugins/autoloader/prism-autoloader";
import Markdown from 'react-markdown'

const ContentDisplay = ({ content, isCode, language }) => {
  const codeRef = useRef(null);
  const [isCopied, setisCopied] = useState(false);
  // const copyToClipboard = () => {
  //   navigator.clipboard
  //     .writeText(codeRef.current.innerText)
  //     .then(() => {
  //       setisCopied(true);
  //       setTimeout(() => {
  //         setisCopied(false);
  //       }, 2000);
  //     })
  //     .catch((err) => {
  //       console.error("Failed to copy:", err);
  //     });
  // };

  useEffect(() => {
    // Manually invoke Prism to highlight code
    Prism.highlightAll();
  }, [content, language]);
  

  // Function to add custom toolbar buttons
  // const addToolbarButton = () => {
  //   window.Prism.plugins.toolbar.registerButton('copy-to-clipboard', env => {
  //     const button = document.createElement('button');
  //     button.innerText = 'Copy';
  //     button.addEventListener('click', () => {
  //       navigator.clipboard.writeText(env.code).then(() => {
  //         console.log('Code copied to clipboard');
  //         // Optionally, show a success message
  //       }).catch(err => {
  //         console.error('Failed to copy:', err);
  //       });
  //     });
  //     return button;
  //   });
  // };

  useEffect(() => {
    // if (window.Prism && window.Prism.plugins && window.Prism.plugins.toolbar) {
    //   addToolbarButton();
    // }
    if (window.Prism && window.Prism.plugins && window.Prism.plugins.autoloader) {
      Prism.plugins.autoloader.languages_path = 'https://cdn.jsdelivr.net/npm/prismjs@1.29.0/components/';
    }
    
  }, []);
  if (isCode) {
    return (
      <div>        
        <pre>
          <code ref={codeRef} className={`language-${language}`}>
            {content}
          </code>
        </pre>
        {/* <div className="content-copy-btn">
          {isCopied ? (
            <IconButton aria-label="assignmentTurnedInIcon" size="small">
              <AssignmentTurnedInIcon fontSize="small" />
            </IconButton>
          ) : (
            <Tooltip title="copy content">
              <IconButton
                aria-label="contentCopyIcon"
                size="small"
                onClick={copyToClipboard}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </div> */}
      </div>
    );
  } else {
    return <Markdown>{content}</Markdown>;
  }
};

export default ContentDisplay;
