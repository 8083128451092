import React from "react";
import "./App.css";
import {SocketContext, socket} from './socket';
import ChildComponent from "./ChildComponent";
function App() {
  return (
    <SocketContext.Provider value={socket}>
      <ChildComponent />
    </SocketContext.Provider>
  );
}


export default App;
